<template>
  <div
    class="account-control"
    :class="{
      'account-control--fade': getShouldFade
    }"
  >
    <template v-if="isInit">
      <button
        class="account-control__btn ui-btn"
        @click.stop="handleControlClicked"
        aria-label="Login"
      >
        <AccountIconFilled class="account-control__icon" v-if="authJWT" />
        <template v-else>
          <Cross v-if="active" class="account-control__close-icon" />
          <AccountIcon class="account-control__icon" v-else />
        </template>
      </button>
      <span
        class="account-control__name text-body"
        v-if="authJWT && profile && profile.firstName"
      >
        {{ UI_ELEMENT("navigation_greeting") }} {{ profile.firstName }}
      </span>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex"
import AccountIcon from "./../../assets/account_icon.svg"
import AccountIconFilled from "./../../assets/account_icon_filled.svg"
import Cross from "@/assets/cross.svg"

export default {
  props: {
    active: Boolean
  },

  data: () => ({
    isInit: false
  }),

  components: {
    AccountIconFilled,
    AccountIcon,
    Cross
  },

  mounted() {
    this.initAccountSession()
  },

  methods: {
    ...mapMutations("ui", ["SET_NAV_ACTIVE_ELEMENT"]),
    ...mapActions("identity", ["currentAuthenticatedUser"]),
    ...mapMutations("identity", ["SET_USERINFO"]),

    async initAccountSession() {
      this.debug.log("initAccountSession - AccountControl")
      // Check if there is an existing authenticated user
      if (!this.authInProgress) {
        await this.currentAuthenticatedUser().catch(() => {
          // If not, then clear out any lingering user data
          this.SET_USERINFO({ user: null })
        })
        this.isInit = true
      } else {
        // wait for result of any authInProgress
        const _unWatch = this.$watch(
          "authInProgress",
          newVal => {
            if (!newVal) {
              _unWatch()
              this.isInit = true
            }
          },
          { immediate: true }
        )
      }
    },

    handleControlClicked() {
      if (this.authJWT) {
        this.$router.push("/account")
      } else {
        this.SET_NAV_ACTIVE_ELEMENT(
          this.navActiveElement === "login" ? null : "login"
        )
      }
    }
  },

  computed: {
    ...mapState("identity", ["authJWT", "authInProgress"]),
    ...mapState("account", ["profile", "getIsUserLoggedIn"]),
    ...mapGetters("breakpoints", ["GET_IS_BELOW_BREAKPOINT"]),
    ...mapState("ui", ["navActiveElement"]),

    getShouldFade() {
      return (
        this.GET_IS_BELOW_BREAKPOINT("md") &&
        this.navActiveElement &&
        this.navActiveElement !== "login"
      )
    }
  }
}
</script>

<style lang="scss">
.account-control {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  transition: opacity 0.3s linear;

  &--fade {
    opacity: 0.5;
  }

  @include media-breakpoint-up(lg) {
    flex-direction: row-reverse;
  }

  &__name {
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
      width: 220px;
      text-align: left;
      margin-left: 20px;
    }

    @include media-breakpoint-up(lg) {
      text-align: right;
      margin-left: unset;
      margin-right: 20px;
    }
  }

  &__btn {
    position: relative;
    cursor: pointer;
    margin: 0 -8px;
    padding: 0 8px;
    pointer-events: all;
  }

  &__icon,
  &__close-icon {
    width: 100%;
    height: 22px;
    mix-blend-mode: difference;

    path {
      vector-effect: non-scaling-stroke;
      stroke: $bleach-white;
      stroke-width: 2px;
    }

    @include media-breakpoint-up(xl) {
      height: 26px;
    }

    &--fill {
      fill: $bleach-white;
    }
  }

  &__close-icon {
    transform: scale(0.9);
    transform-origin: center;
  }
}
</style>
